// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-ev-js": () => import("./../../../src/pages/about-ev.js" /* webpackChunkName: "component---src-pages-about-ev-js" */),
  "component---src-pages-batteries-js": () => import("./../../../src/pages/batteries.js" /* webpackChunkName: "component---src-pages-batteries-js" */),
  "component---src-pages-battery-swapping-js": () => import("./../../../src/pages/battery-swapping.js" /* webpackChunkName: "component---src-pages-battery-swapping-js" */),
  "component---src-pages-charging-points-js": () => import("./../../../src/pages/charging-points.js" /* webpackChunkName: "component---src-pages-charging-points-js" */),
  "component---src-pages-environment-js": () => import("./../../../src/pages/environment.js" /* webpackChunkName: "component---src-pages-environment-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

